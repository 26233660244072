import { Container } from "./Profesores";
import { FaShieldAlt, FaClock, FaUserFriends } from 'react-icons/fa';
import styled from "styled-components";
import Footer from "../Footer/Footer";
import foto1 from './img/1.jpg';
import foto2 from './img/2.jpg';
import foto3 from './img/3.jpg';
import foto4 from './img/4.jpg';
import foto5 from './img/5.jpg';
import foto6 from './img/6.jpg';
import foto7 from './img/7.jpg';
import foto8 from './img/8.jpg';
import foto9 from './img/9.jpg';
import foto10 from './img/10.jpg';
import foto11 from './img/11.jpg';
import foto12 from './img/12.jpg';
import foto13 from './img/13.jpg';
import foto14 from './img/14.jpg';
import foto15 from './img/15.jpg';
import foto16 from './img/16.jpg';
import foto17 from './img/17.jpg';
import foto18 from './img/18.jpg';
import foto19 from './img/19.jpg';
import foto20 from './img/20.jpg';
import foto21 from './img/21.jpg';
import foto22 from './img/22.jpg';
import foto23 from './img/23.jpg';
import foto24 from './img/24.jpg';
import foto25 from './img/25.jpg';
import foto26 from './img/26.jpg';
import foto27 from './img/27.jpg';
import foto28 from './img/28.jpg';
import foto29 from './img/29.jpg';
import foto30 from './img/30.jpg';
import foto31 from './img/31.jpg';
import img_box from './img/img_box.jpg'

const FooterContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 700px) {
    width: 110%;
  }
`;

const ContainerPresentation = styled.div`
  margin-top: 90px;
  width: 100%;
  padding: 20px; 
  
  @media (max-width: 700px) {
    width: 110%;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Box = styled.div`
  width: 30%;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (max-width: 700px) {
    width: 100%;
    margin-bottom: 20px;
  }

  .icon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

const ImageBox = styled.div`
  position: relative;
  background-image: url(${img_box});
  background-size: cover;
  height: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
    color: #fff;
    text-align: center;
    padding: 20px;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
  }
`

const Tittle1 = styled.div`
  color: white;
  text-align: center;
  margin: 10px;
  font-size: 40px;

  @media (max-width: 700px) {
    font-size: 22px;
  }
`

export const Profesores = () => {
  return (
  <div>
        <ContainerPresentation>
        <Tittle1><h1>Nuestro Equipo de Talento Humano</h1></Tittle1>
      <BoxContainer>
        <Box>
          <FaShieldAlt className="icon" />
          <h3>Garantías de Aprendizaje</h3>
          <p>Los docentes se comprometen a proporcionar un entorno de aprendizaje seguro y protegido para todos los estudiantes.</p>
        </Box>
        <Box>
          <FaClock className="icon" />
          <h3>Tiempo de Calidad</h3>
          <p>Los docentes se esfuerzan por ofrecer un tiempo de enseñanza de calidad, maximizando cada momento de aprendizaje.</p>
        </Box>
        <Box>
          <FaUserFriends className="icon" />
          <h3>Disponibilidad</h3>
          <p>Los docentes están disponibles para apoyar a los estudiantes en sus necesidades educativas y proporcionar orientación cuando sea necesario.</p>
        </Box>
      </BoxContainer>
      <ImageBox>
        <div className="content">
          <h2>Capacidades Docentes</h2>
          <p>Nuestros docentes cuentan con una amplia experiencia en el uso de tecnologías educativas innovadoras, incluyendo React. Su dedicación y conocimientos garantizan una enseñanza de calidad y un acompañamiento eficaz a los estudiantes en su proceso de aprendizaje. Respaldados por años de experiencia en el campo de la educación lingüística, nuestros profesores están comprometidos con el éxito académico de cada estudiante. Además de su dominio en las metodologías de enseñanza tradicionales, están siempre actualizados con las últimas tendencias y herramientas en el ámbito educativo.</p>
  
        </div>
      </ImageBox>
    </ContainerPresentation>
    <Container>
      <section className="reviewsSection">
        <div className="reviewTextSection">
        </div>
        <div className="allReviews">
          <div className="review">
            <div className="profilePic">
              <img src={foto1} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Ernesto Rosero
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto2} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Heber Pesillo
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto3} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Jose Diaz
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto4} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                David Cabezas
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto5} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Brayan Anganoy
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto6} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Alexandra Molina
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles - Frances"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto7} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Mayra Hernandez
              </div>
              <div className="reviewerLocation">
                Sede Pasto  
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto8} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Jennifer Arteaga
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto9} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Eylim Gomez
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto10} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Isabel Burbano
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto11} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Natalia Chavez
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto12} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Alejandra Delgado
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto13} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                David Guaitarilla
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles - Portugues"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto14} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Luis Bravo
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto15} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Angela Bastidas
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto16} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Angelica Cordoba
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto17} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Yaneth Quiroz
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto18} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Raul Riascos
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>


          <div className="review">
            <div className="profilePic">
              <img src={foto19} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Gabriel Burgos
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto20} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Leider Narvaez
              </div>
              <div className="reviewerLocation">
               Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto21} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Angela Rosas
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles - Frances"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto22} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Carolina Erazo
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles - Italiano"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto23} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Juan Guevara
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto24} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Julian Insuasty
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto25} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Daniel Timana
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto26} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Anny Tez
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto27} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Ivon Rosero
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>

          <div className="review">
            <div className="profilePic">
              <img src={foto28} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Johana Diaz
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto29} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Ana Victoria Ascuntar
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto30} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Daniela Bravo
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="profilePic">
              <img src={foto31} />
            </div>
            <div className="reviewText">
              <div className="reviewerName">
                Edward Meneses
              </div>
              <div className="reviewerLocation">
                Sede Pasto
              </div>
              <div className="reviewerReview">
                <em>"Idiomas, Ingles"</em>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterContainer>
        <div className="FooterContainer">
          <Footer/>
        </div>
      </FooterContainer>
    </Container>
  </div>
  );
};