import React from "react";
import styled, { keyframes } from "styled-components";
import Footer from "../Footer/Footer";
import HeaderImage from "./img/header.jpg";

const FooterContainer = styled.div`
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1d3d;
  padding: 80px;
  color: white;
  font-family: "Poppins", sans-serif;
  animation: ${fadeIn} 1s ease-in;
  min-height: 100vh;

  @media (max-width: 700px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  width: 80%;
  height: 100px;
  background-image: url(${HeaderImage});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  padding: 40px 0;
  text-align: center;
  position: relative;
  margin: 90px 0 40px 0;

  @media (max-width: 700px) {
    height: 100px;
    padding: 20px 0;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  position: relative;
  z-index: 1;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1.8rem;
    padding: 0 10px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  z-index: 0;
`;

const RequirementsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 30px;
  padding: 20px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const RequirementsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequirementBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  animation: ${fadeIn} 1s ease-in;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 700px) {
    padding: 15px;
    font-size: 1rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 700px) {
    padding: 8px;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 700px) {
    padding: 8px;
  }
`;

const Works = () => {
  return (
    <div>
      <Container>
        <Header>
          <Overlay />
          <Title>Requisitos y habilidades</Title>
        </Header>
        <RequirementsContainer>
          <RequirementsList>
            <RequirementBox>✔️ Licenciatura en idiomas</RequirementBox>
            <RequirementBox>
              ✔️ Nivel de dominio según Marco Común Europeo B2 - C1
            </RequirementBox>
            <RequirementBox>✔️ Honestidad</RequirementBox>
            <RequirementBox>✔️ Liderazgo</RequirementBox>
            <RequirementBox>✔️ Sentir pasión por el progreso</RequirementBox>
            <RequirementBox>✔️ Creatividad</RequirementBox>
            <RequirementBox>✔️ Trabajo en equipo</RequirementBox>
            <RequirementBox>✔️ Inteligencia emocional</RequirementBox>
            <RequirementBox>✔️ Vocación para enseñanza</RequirementBox>
            <RequirementBox>✔️ Proactividad</RequirementBox>
            <RequirementBox>✔️ Dinamicidad</RequirementBox>
            <RequirementBox>✔️ Positivismo</RequirementBox>
          </RequirementsList>
          <FormContainer>
            <Form>
              <Title style={{ fontSize: "1.5rem", marginBottom: "20px" }}>
                Nos encantaría conocerte, puedes aplicar completando el
                siguiente formulario.
              </Title>
              <Input type="text" placeholder="Nombre completo" />
              <Input type="email" placeholder="Correo electrónico" />
              <Input type="tel" placeholder="Teléfono" />
              <Button type="submit">Enviar</Button>
            </Form>
          </FormContainer>
        </RequirementsContainer>
      </Container>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  );
};

export default Works;
