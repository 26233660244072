import React from 'react';
import './Sedes.css';
import imagen1 from './Sedes.img/sede-principal.png';
import imagen2 from './Sedes.img/sede-kids.png';
import imagen3 from './Sedes.img/sede-ipiales.png';
import imagen4 from './Sedes.img/sede-ParqueBolivar.png';
import Footer from '../Footer/Footer.jsx';
import styled from 'styled-components';

const FooterContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 700px) {
    width: 140%;
  }
`;

const Sedes = () => {
  const locations = [
    {
      iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d498.6132625916757!2d-77.2822570501375!3d1.225234020353053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2ed5935adb99a1%3A0xb760564cf92fcfd3!2sCambridge%20Academia%20de%20Lenguajes!5e0!3m2!1ses!2sco!4v1717693925614!5m2!1ses!2sco',
      image: imagen1,
      title: 'CAMBRIDGE SEDE PRINCIPAL',
      description: 'Instalaciones principales de Cambridge Academy. Contamos con n aulas equipadas con todo lo necesario para garantizar el aprendizaje. Además, ofrecemos un laboratorio informático para realizar los exámenes internacionales.',
      address: 'Carrera 35 N° 19 - 102 Versalles, Pasto',
      phone: '317 3169220'
    },
    {
      iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.226102112824!2d-77.28118279037349!3d1.2263700187179434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2ed479616e7c9f%3A0xe2e635bb83135cb3!2sCambridge%20sede%20b!5e0!3m2!1ses!2sco!4v1718053323088!5m2!1ses!2sco',
      image: imagen2,
      title: 'CAMBRIDGE KIDS',
      description: 'Instalaciones en la ciudad de Pasto, con todo lo necesario para nuestros kids, un ambiente más lúdico y divertido, donde aprender lenguajes será toda una experiencia megnifica.',
      address: 'Carrera 34A N° 20 - 88, Pasto',
      phone: '6027230894 - 3185774865'
    },
    {
      iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.698426050499!2d-77.65046713319413!3d0.8328126653276502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e296bdeb997b317%3A0xe2c7c61e0f03e11c!2sCambridge%20Academy%20Of%20Languages!5e0!3m2!1ses!2sco!4v1717722716945!5m2!1ses!2sco',
      image: imagen3,
      title: 'CAMBRIDGE IPIALES',
      description: 'Instalaciones de Cambridge Academy en la ciudad de Ipiales. Contamos con los recursos humanos y de infraestructura para brindar cursos a adultos y niños.',
      address: 'Carrera 8 # 24A - 64 Barrio San Fernando, Ipiales',
      phone: '3175806885'
    },
    {
      iframe: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d592.958932529835!2d-77.27088476007958!3d1.2047639313780794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2ed479464191cf%3A0x11bb5009a298b78a!2sCambridge%20Academy%20of%20Languages!5e0!3m2!1ses!2sco!4v1736959272806!5m2!1ses!2sco" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
      image: imagen4,
      title: 'CAMBRIDGE PARQUE BOLIVAR',
      description: 'Instalaciones de Cambridge Academy en la ciudad de Ipiales. Contamos con los recursos humanos y de infraestructura para brindar cursos a adultos y niños.',
      address: 'Calle 21 # 12 - 45, Pasto',
      phone: '3170495555'
    }
  ];

  return (
    <div className="page-container">
      <div className="sedes-container">
        {locations.map((location, index) => (
          <div key={index} className="sede">
            <div className="sede-description">
              <h3 className='tittleF'>{location.title}</h3>
              <p className='textF'>{location.description}</p>
              <p className='address'><strong>Dirección:</strong> {location.address}</p>
              <p className='phone'><strong>Teléfonos:</strong> {location.phone}</p>
            </div>
            <img src={location.image} alt={location.title} className="sede-image" />
            <iframe
              src={location.iframe}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        ))}
      </div>
      <div className="footer">
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </div>
    </div>
  );
};

export default Sedes;