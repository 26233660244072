import React from "react";
import styled, { keyframes } from "styled-components";
import Footer from '../Footer/Footer';
import newImage from "./img/new_image.jpg";
import newImage2 from "./img/new_image2.jpg";
import {
  FaUserGraduate,
  FaUserTie,
  FaUserNinja,
  FaUserAstronaut,
  FaWifi,
  FaTabletAlt,
  FaUserCheck,
  FaCreditCard,
  FaClock,
  FaCalendarAlt,
  FaChild,
  FaMoneyBillWave,
  FaChalkboardTeacher,
  FaBook,
} from "react-icons/fa";

const FooterContainer = styled.div`
  @media screen and (max-width: 700px){
    font-size: 12px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
`;

const CoursesNav = styled.div`
  background-color: #007bff;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 50px;

  @media (max-width: 700px) {
    padding: 15px;
    font-size: 1.2em;
  }
`;

const Title = styled.h1`
  font-size: 2em;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1.5em;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  animation: ${fadeIn} 1s ease-in-out;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  border-radius: 15px;
  overflow: hidden;

  @media (max-width: 700px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
`;

const TextContainer = styled.div`
  flex: 2;
  background-color: #2e2e4e;
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  line-height: 1.6;

  @media (max-width: 700px) {
    width: 100%;
    padding: 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-top: 20px;
  color: #00aaff;

  @media (max-width: 700px) {
    font-size: 1.2em;
    text-align: center;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ListItem = styled.li`
  margin: 10px;
  background-color: #2e2e4e;
  padding: 20px;
  border-radius: 20px;
  color: #ffffff;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #3e3e5e;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    padding: 15px;
    margin: 10px 0;
  }
`;

const IconContainer = styled.div`
  font-size: 3em;
  margin-bottom: 10px;
`;

const HorizontalList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
`;

const HorizontalItem = styled.div`
  margin: 10px;
  background-color: #2e2e4e;
  padding: 20px;
  border-radius: 20px;
  color: #ffffff;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: ${fadeIn} 1s ease-in-out forwards;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #3e3e5e;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    padding: 15px;
    margin: 10px 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Button = styled.a`
  background-color: #007bff;
  color: white;
  padding: 15px 20px;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  width: 200px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 700px) {
    width: 100%;
    font-size: 1em;
  }
`;

const IconButton = styled.span`
  margin-right: 10px;
`;

const CambridgeVirtual = () => {
  return (
    <div>
    <Container>
      <CoursesNav>
        <Title>Cambridge Virtual</Title>
      </CoursesNav>
      <Content>
        <Section>
          <TextContainer>
            <p>El aprendizaje en línea es el presente y el futuro</p>
            <p>Programa de Inglés a Nivel de Eficiencia Comunicativa personalizado o en grupos de máximo cinco personas.</p>
          </TextContainer>
          <ImageContainer>
            <Image src={newImage} alt="Cambridge Virtual" />
          </ImageContainer>
        </Section>
        <SectionTitle>OBJETIVO</SectionTitle>
        <TextContainer>
          <p>Manejar las cuatro habilidades del idioma de estudio: leer, escribir, escuchar, hablar a nivel de B2-C1 para certificarse internacionalmente. Un profesor se dedica en vivo y en tiempo real exclusivamente a un estudiante de forma intensiva o a un grupo de máximo cinco personas, de acuerdo a la disponibilidad horaria del estudiante.</p>
        </TextContainer>
        <SectionTitle>DURACIÓN, HORARIOS Y EDADES</SectionTitle>
        <HorizontalList>
          <HorizontalItem>
            <IconContainer>
              <FaClock />
            </IconContainer>
            <p>Depende de la intensidad de horas que tome el estudiante al día y la frecuencia con que asista en la semana.</p>
          </HorizontalItem>
          <HorizontalItem>
            <IconContainer>
              <FaCalendarAlt />
            </IconContainer>
            <p>Los selecciona el estudiante de acuerdo a sus necesidades e intereses.</p>
          </HorizontalItem>
          <HorizontalItem>
            <IconContainer>
              <FaChild />
            </IconContainer>
            <p>Abierto para todas las edades.</p>
          </HorizontalItem>
        </HorizontalList>
        <SectionTitle>INFORMACIÓN</SectionTitle>
        <Section>
          <TextContainer>
            <p>El o los estudiantes podrán determinar un horario de realización del curso. En caso que el o los estudiantes no puedan definir un horario, podrán programar sus sesiones de clases por lo menos con un día de anticipación.</p>
            <p>Garantizamos este proceso de formación virtual con: metodología y pedagogía ajustada al Marco Común Europeo de referencia, equipos y conexiones de última generación que permitirán que las clases se dicten en tiempo real y con pedagogos certificados internacionalmente, talleres conversacionales y de fonética con hablantes nativos.</p>
            <p>Al finalizar el programa, el estudiante podrá certificarse internacionalmente a través de nuestra misma Institución porque contamos con los convenios con el British Council, ETS de los Estados Unidos y Oxford Quality de la Universidad de Oxford.</p>
          </TextContainer>
          <ImageContainer>
            <Image src={newImage2} alt="Cambridge Virtual" />
          </ImageContainer>
        </Section>
        <SectionTitle>NIVELES</SectionTitle>
        <List>
          <ListItem>
            <IconContainer>
              <FaUserGraduate />
            </IconContainer>
            Principiante (Fase I, II y III)
          </ListItem>
          <ListItem>
            <IconContainer>
              <FaUserTie />
            </IconContainer>
            Intermedio (Fase IV, V y VI)
          </ListItem>
          <ListItem>
            <IconContainer>
              <FaUserNinja />
            </IconContainer>
            Avanzado (Fase VII, VIII y IX)
          </ListItem>
          <ListItem>
            <IconContainer>
              <FaUserAstronaut />
            </IconContainer>
            Académico (Fase X, XI y XII)
          </ListItem>
        </List>
        <SectionTitle>METODOLOGÍA Y BENEFICIOS</SectionTitle>
        <List>
          <ListItem>
            <IconContainer>
              <FaWifi />
            </IconContainer>
            Clases Virtuales en Tiempo Real
          </ListItem>
          <ListItem>
            <IconContainer>
              <FaTabletAlt />
            </IconContainer>
            Tecnología Educativa de Punta
          </ListItem>
          <ListItem>
            <IconContainer>
              <FaUserCheck />
            </IconContainer>
            Certificación Internacional
          </ListItem>
          <ListItem>
            <IconContainer>
              <FaCreditCard />
            </IconContainer>
            Facilidad de Pago
          </ListItem>
        </List>
        <SectionTitle>RESPONSABILIDADES Y COMPROMISOS DEL ESTUDIANTE</SectionTitle>
        <TextContainer>
          <p>Es responsabilidad del estudiante asistir puntualmente a las clases programadas, completar todas las tareas asignadas y participar activamente en todas las actividades del curso.</p>
          <p>El estudiante debe mantener una actitud respetuosa y colaborativa durante las clases y respetar las normas de convivencia establecidas por la institución.</p>
          <p>El compromiso del estudiante es esencial para el logro de los objetivos del curso y para su propio progreso en el aprendizaje del idioma.</p>
        </TextContainer>
        <SectionTitle>MANUALES Y GUÍAS</SectionTitle>
        <TextContainer>
          <p>Manuales y guías que “Cambridge Virtual” ofrece, para que tengas la mejor experiencia al estudiar con nosotros. En la sección de pagos virtuales también encontrará la forma de inscribirse o matricularse por Cambridge Virtual.</p>
        </TextContainer>
        <ButtonContainer>
          <Button href="https://www.cambridgeacademy.edu.co/Docs/PAGOS%20VIRTUALES.pdf" target="_blank">
            <IconButton><FaMoneyBillWave /></IconButton>Pagos Virtuales
          </Button>
          <Button href="https://www.cambridgeacademy.edu.co/Docs/PLATAFORMA%20VIRTUAL.pdf" target="_blank">
            <IconButton><FaChalkboardTeacher /></IconButton>Plataforma de Clases Virtuales
          </Button>
          <Button href="https://www.cambridgeacademy.edu.co/Docs/eBOOK.pdf" target="_blank">
            <IconButton><FaBook /></IconButton>Uso de eBOOK o Libro Virtual
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
    <div>
    <FooterContainer>
      <Footer />
    </FooterContainer>
    </div>
  </div>
  );
};

export default CambridgeVirtual;

