import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styled from 'styled-components';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import slide_image_0 from './img/0.jpg';
import slide_image_1 from './img/1.jpg';
import slide_image_2 from './img/2.png';
import slide_image_3 from './img/3.jpeg';
import slide_image_4 from './img/4.png';
import slide_image_5 from './img/5.jpg';
import slide_image_6 from './img/6.jpg';
import slide_image_7 from './img/7.jpg';
import slide_image_8 from './img/8.jpg';
import slide_image_9 from './img/9.jpg';
import slide_image_10 from './img/10.jpg';
import slide_image_11 from './img/11.jpg';

const Slider = styled.div`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root{
  --primary-color: #ffffff;
  --white: #000000;
  --bg: #151333;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media (min-width: 1440px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.7;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5;
  }
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #ff0000;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 4rem 1rem;
}

h1.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
  color: #fff;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 40rem;
  height: 50rem;
  position: relative;
  font-size: 12px;
}

@media (max-width: 700px) {
  .swiper_container {
    zoom: 0.55;
  }
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table {
  color: white;
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid white;
  padding: 0.5rem;
}
`

const tableData = [
  ["Starters", "0", "6 a 7 años"],
  ["", "1-A - 1-B", "6 a 7 años"],
  ["", "2-A - 2-B", "8 a 9 años"],
  ["Movers", "3-A - 3-B", "8 a 9 años"],
  ["", "4-A - 4-B", "8 a 9 años"],
  ["Flyers", "5-A - 5-B", "9 a 10 años"],
  ["", "6-A - 6-B", "9 a 10 años"]
];

function Courses() {
  return (
    <Slider>
      <div className="container">
        <h1 className="heading">Cursos</h1>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container">
          <SwiperSlide>
            <img src={slide_image_0} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>JÓVENES Y ADULTOS</h2>
              <h1>Objetivo</h1>
              <p>Manejar las cuatro habilidades de la lengua de estudio: leer, escribir, escuchar y hablar a nivel B2-C1 de acuerdo al Marco Común Europeo, para certificarse internacionalmente con APTIS, TOEFL iBT, IELTS ó F.C.E., PET, KET y MET.</p>
              <h2>Niveles</h2>
              <ul>
                  <li><strong>Principiante:</strong> Fases I, II y III- </li>
                  <li><strong>Intermedio:</strong> Fases IV, V y VI</li>
                  <li><strong>Avanzado:</strong> Fases VII, VIII y IX- </li>
                  <li><strong>Perfeccionamiento:</strong> Fases X-A, X-B y Training</li>
                  <li>Aplicación de exámenes internacionales</li>
              </ul>
              <h2>Duración</h2>
              <ul>
                  <li><strong>En horario de lunes a viernes:</strong> un año aproximadamente</li>
                  <li><strong>En horarios de martes y jueves:</strong> un año aproximadamente</li>
                  <li><strong>En horarios de fines de semana:</strong> dos años aproximadamente</li>
              </ul>

              <h2>Horarios</h2>
              <ul>
              <li><strong>Martes a jueves:</strong> 
                  <ul>
                      <li>8:00 A.M.</li>
                      <li>10:00 A.M.</li>
                      <li>2:00 P.M.</li>
                      <li>4:00 P.M.</li>
                      <li>6:30 P.M.</li>
                  </ul>
              </li>
        <li><strong>Lunes a jueves:</strong> 6:30PM a 8:30PM</li>
        <li><strong>Fines de semana:</strong> 
            <ul>
                <li>Sábados de 8:30 A.M. a 12:00 M</li>
                <li>2:00 P.M. a 5:30 P.M.</li>
            </ul>
        </li>
    </ul>
    <h2>Edades</h2>
    <p>A partir de 13 años.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_1} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Inglés Intro</h2>
              <p>Es un curso que está diseñado para realizarse en dos fases, A y B, dirigido a estudiantes jóvenes o adultos que no tienen conocimientos previos del inglés. A través de este programa adquieren los conocimientos básicos (nivelación bachillerato) para enfrentar sin ninguna dificultad el programa a nivel de eficiencia comunicativa.</p>
              <h2>Fases</h2>
              <ul>
                  <li>INTROA </li>
                  <li>-INTROB</li>
              </ul>

              <h2>Duración</h2>
              <p>Un mes aproximadamente en horario entre semana y dos meses aproximadamente en horario fin de semana.</p>

              <h2>Horarios</h2>
              <ul>
                  <li>Martes a Jueves de 8-10AM, 10-12M ; 2-4PM, 4-6:30PM.</li>
                  <li>Lunes a jueves de 6:30-8PM -</li>
                  <li>Sábados de 8:30 A.M. a 12:00 M</li>
                  <li>Sábados de 2:00 P.M. a 5:30 PM</li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_2} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Cambridge Kids</h2>
              <p>El objetivo de Cambridge Kids es lograr que los niños (entre 6 y 11 años) alcancen un nivel B1 según Marco Común Europeo. Obteniendo competencias en el dominio del inglés de una primaria bilingüe.</p> <br />
              <table className="table">
                <thead>
                  <tr>
                    <th>Nivel</th>
                    <th>Fases</th>
                    <th>Edad</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((rowData, rowIndex) => (
                    <tr key={rowIndex}>
                      {rowData.map((cellData, cellIndex) => (
                        <td key={cellIndex}>{cellData}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <h2>Fases</h2>
              <ul>
                  <li>Fase A</li>
                  <li>Fase B</li>
              </ul>

              <h2>Duración</h2>
              <p>Dos meses y medio cada fase aproximadamente, en todos sus horarios.</p>

              <h2>Horarios</h2>
              <ul>
                  <li>Lunes y Viernes de 5:00 P.M. a 6:30 P.M.</li>
                  <li>- Sábados de 8:30 A.M. a 12:00 M.</li>
                  <li>- Sábados de 2:00 P.M. a 5:30 P.M.</li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_3} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Personalizado</h2>
                <p>Programa de Inglés Personalizado Intensivo a Nivel de Eficiencia Comunicativa para niños y adultos.</p>

                <h2>Objetivo</h2>
                <p>Manejar las cuatro habilidades del idioma de estudio: leer, escribir, escuchar, hablar a nivel de B2-C1 para certificarse internacionalmente. Un profesor se dedica exclusivamente a un estudiante de forma intensiva, de acuerdo a la disponibilidad horaria del estudiante.</p>

                <h2>Niveles</h2>
                <ul>
                    <li>Principiante (Fases I, II y III)</li>
                    <li>Intermedio (Fases IV, V y VI)</li>
                    <li>Avanzado (VII, VIII, IX)</li>
                    <li>Perfeccionamiento (Fases X-A, X-B y Training)</li>
                    <li>Aplicación de exámenes internacionales</li>
                </ul>

                <h2>Duración</h2>
                <p>Depende de la intensidad de horas que tome el estudiante al día y la frecuencia con que asista en la semana.</p>

                <h2>Horarios</h2>
                <p>Los selecciona el estudiante de acuerdo a sus necesidades e intereses.</p>

                <h2>Edades</h2>
                <p>A partir de los 6 años (programa de niños) a partir de los 13 años (programa de adultos).</p>

                <h2>Información</h2>
                <p>Cuando el estudiante no puede determinar un horario fijo para su curso personalizado, puede optar por el curso personalizado por sesiones; en el cual el estudiante programa sus clases por lo menos con un día de anticipación, y la Institución le asignará un docente para que dicte las clases en el horario que el estudiante ha elegido.</p>

            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_4} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>CLUB "FRIENDS MEETING"</h2>
              <h1>Objetivo</h1>
                <p>Garantizar práctica permanente y gratuita del idioma aprendido a los egresados y estudiantes de niveles avanzados. Talleres práctica de Speaking, laboratorios, videos y fonética.</p>
                <h2>Duración</h2>
                <p>Permanente</p>
                <h2>Horarios</h2>
                <p>Lunes a viernes, en la mañana, tarde y noche.<br />Fines de semana, sábados de 10:00 A.M. a 12:00 M.</p>
                <h2>Edades</h2>
                <p>Niños, jóvenes y adultos.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_5} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Francés</h2>
              El Francés es la lengua extranjera que más personas aprenden, después del inglés y la novena lengua más hablada en el mundo. El Francés es la lengua internacional de la cocina, la moda, el teatro, el arte visual, la danza y la arquitectura. Conocer Francés permite acceder, en versión original, a los grandes textos de la literatura francesa y francófona, pero igualmente al cine y a la música. Francia es el país más visitado del mundo, con más de 70 millones de visitantes por año. Por eso debes aprender Francés.
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_6} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Alemán</h2>
              <p>En un mercado laboral globalizado poseer conocimientos de Alemán mejora sus oportunidades de trabajo en las empresas alemanas tanto en su país como en otras partes del mundo. Un buen dominio del Alemán aumenta las oportunidades profesionales cuando su empleador desarrolla actividades a nivel mundial.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_7} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Italiano</h2>
              <p>En cultura, moda y comida Italia es líder en muchos sectores considerados de alta calidad, desde la moda, el arte, la historia, la cultura, pasando por el deporte, turismo, es decir, hablar italiano permitirá entrar en esos mundos y poder entenderlos mejor.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_8} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Portugués</h2>
              <p>El aprender el Portugués es una de las claves para desarrollar confianza y relaciones de largo plazo, que luego pueden materializarse en la generación de proyectos económicos o de otra envergadura. Brasil es casi un continente, y está dentro de una región que se entiende como la esperanza para los próximos 20 años. Estar vinculado a ese país ofrece una gran ventaja competitiva a las personas de negocios. El Portugués es hablado por 250 millones de personas en el mundo.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_9} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Español</h2>
              <p>Hablar español permite comunicarse con más de 495 millones de personas en todo el mundo. Comunicarse en español también aumenta en gran medida las opciones de empleo con muchas de las principales economías del mundo que demandan hispanohablantes. El español es la segunda lengua más hablada en el mundo, después del chino, y es la segunda lengua más utilizada en la comunicación internacional.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_10} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Lectura rápida</h2>
              <p>El curso es realizado en el horario de sábados de 2:00 a 5:30p.m. o de lunes a viernes de 5:00 a 6:30 p.m. Y tiene una duración de 3 meses y medio en horario de sábados o dos meses cuando es de lunes a viernes.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_11} alt="slide_image" />
            <div className="swiper-slide-content">
              <h2>Policía</h2>
              <p>Curso intensivo de Inglés en verano o para aspirantes a la Policía Nacional</p>
    
    <h2>Objetivo</h2>
    <p>Manejar un Inglés básico (A1 - A2 según el Marco Común Europeo) en las cuatro habilidades de la lengua: leer, escribir, escuchar y hablar.</p>
    
    <h2>Horario</h2>
    <p>Verano: 9 A.M. a 11 A.M. de lunes a viernes ó en la tarde de 4 P.M. a 6 P.M.</p>
    
    <h2>Duración</h2>
    <p>Tiene una duración de un mes y medio, incluye formulario de inscripción y materiales.</p>
    <p>Una vez terminado el curso intensivo con nota mínima de 3.5, el estudiante puede continuar en nivel intermedio del programa de Inglés a nivel de eficiencia comunicativa.</p>
            </div>
          </SwiperSlide>
          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </Slider>
  );
}

export default Courses;