import { useEffect, useRef } from 'react'
import './ChooseUsSection.css'
import { AiOutlineBarChart as Chart } from 'react-icons/ai'
import { RiComputerLine as Service } from 'react-icons/ri'
import { MdSupportAgent as Support} from 'react-icons/md'
import { FaPeopleCarry as Community } from 'react-icons/fa'

const ChooseUsSection = () => {
  const cardsRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
          entry.target.classList.add("fadeIn")
      })
    }, {
      threshold: 0.5
    })
    observer.observe(cardsRef.current)
  }, [])

  return (
    <section className="chooseUsSection">
      <div className="sectionTitle">
        <h1 className='why'>¿Por qué elegirnos?</h1>
      </div>
      <div className="reasonCards" ref={cardsRef}>
        <div className="card">
          <div className="cardSymbol">
            <Chart size={'100px'} color={'#fff'}/>
          </div>
          <div className="cardTitle">
              Excelencia
          </div>
          <div className="cardDesc">
            Estamos ubicados en un alto puesto entre las mejores academias de idiomas del país. Nuestra obligación es llevarles a nuestros estudiantes la mejor calidad de aprendizaje de sus idiomas preferidos y superar sus expectativas en su estancia durante nuestra academia.
          </div>
        </div>
        <div className="card">
          <div className="cardSymbol">
            <Service size={'100px'} color={'#fff'}/>
          </div>
          <div className="cardTitle">
            Accesibilidad
          </div>
          <div className="cardDesc">
            Además de la educación presencial, contamos con muchas formas de acceso a recursos de aprendizaje en línea para reforzar su formación lingüistica de manera que su educación sea integral.
          </div>
        </div>
        <div className="card">
          <div className="cardSymbol">
            <Community size={'100px'} color={'#fff'}/>
          </div>
          <div className="cardTitle">
            Experiencia
          </div>
          <div className="cardDesc">
            Llevamos más de 15 años brindando educación de la más alta calidad con certificaciones internacionales como Bureau Veritas y docentes altamente calificados  para que la educación de nuestros alumnos sea excelente.
          </div>
        </div>
        <div className="card">
          <div className="cardSymbol">
            <Support size={'100px'} color={'#fff'}/>
          </div>
          <div className="cardTitle">
            Soporte inmediato.
          </div>
          <div className="cardDesc">
            Brindamos ayuda inmediata a todos los estudiantes durante los horarios establecidos. <br />Lunes a viernes de 7:00am a 4:00pm <br />Sábados de 7:00am a 11:00am.
          </div>
        </div>
      </div>
    </section>
  )
}
export default ChooseUsSection
