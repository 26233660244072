import React from "react";
import "./About.css";
import styled, { keyframes } from "styled-components";
import convenio1 from "./img/british-council.png";
import convenio2 from "./img/ets.png";
import convenio3 from "./img/Kaplan.png";
import convenio4 from "./img/oxford.jpg";
import Footer from "../Footer/Footer";
import valuesImg from "./img/corporacion.jpg";
import valuesImg2 from "./img/corporacion2.jpg";
import valuesImg3 from "./img/corporacion3.jpg";

const Container = styled.div`
  /* (Estilos actuales que ya tienes en tu componente) */
`;

const Counter = ({ number, duration }) => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    let startTime = null;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const increment = Math.floor((number / (duration * 1000)) * progress);
      if (increment <= number) {
        setCount(increment);
        window.requestAnimationFrame(step);
      } else {
        setCount(number);
      }
    };

    window.requestAnimationFrame(step);
  }, [number, duration]);

  return <h3>{count}</h3>;
};

const About = () => {
  return (
    <div>
      <div className="onlyCont">
        <Container>
          <div className="onlyContainer">
            <div className="containerAbout">
              <div className="Title">Sobre Nosotros</div>
              <div className="misvis">
                <div className="blocks">
                  <div className="mision">
                    <h2>Nuestra Misión</h2>
                    <p>
                      Es una institución de capacitación y formación en idiomas
                      a nivel de eficiencia comunicativa en forma virtual y
                      presencial (leer, escribir, escuchar y hablar según los
                      estándares del Marco común europeo con Referencia a las
                      Lenguas), donde se fomenta el desarrollo humano con una
                      visión universal, respetuosa por la diferencia, la
                      inclusión y cuidadosa del medio ambiente; a través de
                      programas académicos bien estructurados que posibilitan
                      ampliar el horizonte del conocimiento, la tecnología,
                      adquisición de becas y exámenes internacionales e
                      intercambios a otros países del mundo.
                    </p>
                  </div>
                </div>
                <div className="vision">
                  <h2>Nuestra Visión</h2>
                  <p>
                    Se consolidará como un Centro Líder, vanguardista,
                    multicultural y abierto a un público diverso y con una
                    visión universal e inclusiva; ya sea por su origen, ideas,
                    necesidades e intereses; será un espacio para la
                    transformación del conocimiento, el fomento del desarrollo
                    humano con criterios de calidad y exigencia, apoyados por un
                    talento humano creativo, innovador, con respeto por la
                    diferencia, la inclusión y el medio ambiente.
                  </p>
                </div>
              </div>
              <div className="stats">
                <div className="stat">
                  <h3 className="clock">
                    <Counter number={20} duration={8} />+
                  </h3>
                  <p>Años de experiencia</p>
                </div>
                <div className="stat">
                  <h3 className="clock">
                    <Counter number={15000} duration={8} />+
                  </h3>
                  <p>Estudiantes egresados</p>
                </div>
                <div className="stat">
                  <h3 className="clock">
                    <Counter number={100} duration={8} />+
                  </h3>
                  <p>Docentes calificados</p>
                </div>
              </div>
              <div className="quality">
                <h2>Calidad Educativa</h2>
                <p>
                  En nombre de nuestra Corporación Cultural Educentro,
                  extendemos un cordial saludo a nuestros estudiantes,
                  profesores, padres de familia, comunidad en general y a todas
                  aquellas personas que de una u otra forma se encuentran
                  comprometidas con la educación como herramienta para la
                  construcción de un país, un continente y un mundo mejor.
                  Nuestro compromiso es mantenernos en constante evolución,
                  aplicando criterios de calidad y excelencia en la formación
                  integral de nuestros estudiantes, fomentando valores como el
                  respeto, la responsabilidad y la inclusión.
                </p>
                <p>
                  Contamos con una amplia trayectoria en el ámbito educativo y
                  una metodología innovadora que se adapta a las necesidades de
                  cada estudiante, ofreciendo programas académicos que promueven
                  el desarrollo de habilidades y competencias necesarias para
                  enfrentar los retos del mundo actual. Trabajamos con un equipo
                  de profesionales altamente capacitados y comprometidos con la
                  misión de brindar una educación de calidad que inspire y
                  motive a nuestros estudiantes a alcanzar sus metas y a
                  contribuir positivamente en la sociedad.
                </p>
                <p>
                  Con la mirada puesta en el futuro, continuamos trabajando para
                  consolidarnos como una institución líder en el ámbito
                  educativo, siempre fieles a nuestros principios de inclusión,
                  respeto por la diversidad y cuidado del medio ambiente.
                  Agradecemos la confianza depositada en nosotros y reafirmamos
                  nuestro compromiso de seguir ofreciendo una educación de
                  excelencia que abra puertas y oportunidades para todos.
                </p>
              </div>
              <div className="objectives">
                <div className="objective">
                  <h3>Objetivo General</h3>
                  <p>
                    El objetivo principal de nuestra Corporación Cultural
                    Educentro es proporcionar una educación integral y de
                    calidad que fomente el desarrollo humano y académico de
                    nuestros estudiantes, promoviendo valores como el respeto,
                    la responsabilidad y la inclusión, con el fin de formar
                    ciudadanos comprometidos y preparados para enfrentar los
                    retos del mundo actual.
                  </p>
                </div>
                <div className="objective">
                  <h3>Objetivos Específicos</h3>
                  <p>
                    1. Ofrecer programas académicos bien estructurados que se
                    adapten a las necesidades y capacidades de cada estudiante.
                  </p>
                  <p>
                    2. Fomentar el desarrollo de habilidades y competencias
                    necesarias para el éxito académico y profesional.
                  </p>
                  <p>
                    3. Promover el respeto por la diversidad y la inclusión en
                    todas nuestras actividades y programas.
                  </p>
                  <p>
                    4. Impulsar la innovación y la creatividad en el proceso
                    educativo.
                  </p>
                  <p>
                    5. Establecer alianzas estratégicas con instituciones
                    nacionales e internacionales para ofrecer más y mejores
                    oportunidades a nuestros estudiantes.
                  </p>
                </div>
              </div>
              <div className="values">
                <div className="value">
                  <img
                    src={valuesImg}
                    alt="Values"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                  <h3>Compromiso con la Calidad</h3>
                  <p>
                    Nos comprometemos a mantener altos estándares de calidad en
                    todos nuestros programas y actividades, asegurando una
                    formación integral y de excelencia para nuestros
                    estudiantes.
                  </p>
                </div>
                <div className="value">
                  <img
                    src={valuesImg2}
                    alt="Values"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                  <h3>Innovación y Creatividad</h3>
                  <p>
                    Fomentamos un ambiente de innovación y creatividad, donde se
                    promueve el pensamiento crítico y la resolución de
                    problemas, preparando a nuestros estudiantes para enfrentar
                    los desafíos del mundo actual.
                  </p>
                </div>
                <div className="value">
                  <img
                    src={valuesImg3}
                    alt="Values"
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                  <h3>Inclusión y Respeto por la Diversidad</h3>
                  <p>
                    Valoramos la diversidad y promovemos la inclusión en todas
                    nuestras actividades, asegurando un entorno de respeto y
                    equidad para todos nuestros estudiantes y colaboradores.
                  </p>
                </div>
              </div>
              <div className="partnerships">
                <img className="convenio" src={convenio1} alt="Convenio 1" />
                <img className="convenio" src={convenio2} alt="Convenio 2" />
                <img className="convenio" src={convenio3} alt="Convenio 3" />
                <img className="convenio" src={convenio4} alt="Convenio 4" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="footerCont">
        <Footer />
      </div>
    </div>
  );
};

export default About;