import React, { useState } from "react";
import "./Home.css";
import Hero from "../Hero/Hero";
import ChooseUsSection from "../Choose/ChooseUsSection";
import styled from "styled-components";
import Footer from "../Footer/Footer";
import Aditional from "../Aditional/Aditional";

const FooterSection = styled.div`
  .footerContainer {
    @media (max-width: 700px) {
      width: 120%;
      align-content: center;
    }
  }
`;
const ChooseSection = styled.div`
  .footerContainer {
    @media (max-width: 700px) {
      width: 120%;
      align-content: center;
    }
  }
`;

const AditionalSection = styled.div`
  .aditionalContainer {
    @media (max-width: 700px) {
      width: 120%;
      align-content: center;
    }
  }
`;

const VideoSection = styled.div`
  .videoContainer {
    text-align: center;
    background-color: #00000080;
    margin: 40px 0 -5px 0;
  }
`;

const Home = () => {
  const [showMore, setShowMore] = useState(false);

  // const handleToggle = () => {
  //   setShowMore(!showMore);
  // };

  return (
    <div className="container-elements">
      <Hero />
      <AditionalSection>
        <div className="aditionalContainer">
          <Aditional />
        </div>
      </AditionalSection>
      <ChooseSection>
        <div className="ChooseContainer">
          <ChooseUsSection />
        </div>
      </ChooseSection>
      <VideoSection>
        <div className="videoContainer">
          <iframe
            width="100%"
            height="780px"
            src="https://www.youtube.com/embed/KTmkDvAbgds"
            title="Cambridge Academy of Languages"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </VideoSection>
      <FooterSection>
        <div className="footerContainer">
          <Footer />
        </div>
      </FooterSection>
    </div>
  );
};

export default Home;