import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes, FaHome, FaBook, FaMapMarkerAlt, FaChalkboardTeacher, FaInfoCircle, FaGlobe, FaGraduationCap, FaCalendarAlt, FaBriefcase, FaLaptop, FaEnvelope, FaPaintBrush } from 'react-icons/fa';
import './SideMenu.css';

const SideMenu = ({ isOpen, toggleMenu }) => {
  return (
    <div className={`side-menu ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={toggleMenu}><FaTimes /></button>
      <div className='responsive'>
        <div className="optionMenu">
          <Link to="/" onClick={toggleMenu}>
            <FaHome /><h3>Inicio</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Courses" onClick={toggleMenu}>
            <FaBook /><h3>Cursos</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Sedes" onClick={toggleMenu}>
            <FaMapMarkerAlt /><h3>Sedes</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Profesores" onClick={toggleMenu}>
            <FaChalkboardTeacher /><h3>Docentes</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/About" onClick={toggleMenu}>
            <FaInfoCircle /><h3>Sobre Nosotros</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Exint" onClick={toggleMenu}>
            <FaGlobe /><h3>Ex. Internacionales</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Becas" onClick={toggleMenu}>
            <FaGraduationCap /><h3>Becas</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Event" onClick={toggleMenu}>
            <FaCalendarAlt /><h3>Eventos</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Works" onClick={toggleMenu}>
            <FaBriefcase /><h3>Trabajo</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Virtual" onClick={toggleMenu}>
            <FaLaptop /><h3>Virtual</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Contact" onClick={toggleMenu}>
            <FaEnvelope /><h3>Contacto</h3>
          </Link>
        </div>
        <div className="optionMenu">
          <Link to="/Draw" onClick={toggleMenu}>
            <FaPaintBrush /><h3>Draw</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;