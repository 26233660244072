import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import html2canvas from "html2canvas";

const Container = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background-color: #1e1d3d;
  min-height: 100vh;
  color: white;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  @media (max-width: 700px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 2rem;
`;

const BoardContainer = styled.div`
  width: 90%;
  max-width: 1000px;
  height: 600px;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: white;
  overflow: hidden;
  position: relative;

  @media (max-width: 700px) {
    height: 400px;
  }
`;

const Board = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Instructions = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  /* @media (700px){
  flex-direction: column;
  } */
`;

const ColorButton = styled.button`
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  border: none !important;
  margin: 0 5px !important;
  cursor: pointer !important;
  outline: 2px solid ${({ selected }) => (selected ? "#fff" : "transparent")} !important;
  outline-offset: 2px !important;

  &:hover {
    outline: 2px solid #fff !important;
  }
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const ToolButton = styled(Button)`
  margin: 10px;
`;

const ThicknessPickerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const ThicknessButton = styled.button`
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  border: none !important;
  margin: 0 5px !important;
  cursor: pointer !important;
  background-color: ${({ selected }) =>
    selected ? "grey" : "black"} !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

const TextInput = styled.input`
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  border: 1px solid #000;
  background-color: transparent;
  color: black;
`;

const colors = ["black", "red", "green", "blue", "yellow", "purple", "orange"];
const thicknesses = [2, 5, 10, 15];

const DrawingBoard = () => {
  const navigate = useNavigate();
  const [drawing, setDrawing] = useState(false);
  const [currentColor, setCurrentColor] = useState("black");
  const [currentTool, setCurrentTool] = useState("draw");
  const [currentThickness, setCurrentThickness] = useState(5);
  const [elements, setElements] = useState([]);
  const [inputPosition, setInputPosition] = useState(null);
  const [textValue, setTextValue] = useState("");
  const boardRef = useRef(null);
  const textInputRef = useRef(null);
  const drawInterval = useRef(null);

  useEffect(() => {
    const draw = () => {
      if (drawing && currentTool === "draw") {
        setElements((prev) => [
          ...prev,
          {
            type: "draw",
            color: currentColor,
            thickness: currentThickness,
            x: drawing.x,
            y: drawing.y,
          },
        ]);
      }
      drawInterval.current = requestAnimationFrame(draw);
    };

    drawInterval.current = requestAnimationFrame(draw);

    return () => cancelAnimationFrame(drawInterval.current);
  }, [drawing, currentColor, currentThickness]);

  const startDrawing = (e) => {
    const rect = boardRef.current.getBoundingClientRect();
    const x = e.clientX || e.touches[0].clientX;
    const y = e.clientY || e.touches[0].clientY;
    if (currentTool === "draw" || currentTool === "erase") {
      setDrawing({
        x: x - rect.left,
        y: y - rect.top,
      });
    } else if (currentTool === "text") {
      setInputPosition({
        x: x - rect.left,
        y: y - rect.top,
      });
    }
  };

  const stopDrawing = () => {
    setDrawing(false);
  };

  const handleDrawingMove = (e) => {
    if (drawing) {
      const rect = boardRef.current.getBoundingClientRect();
      const x = e.clientX || e.touches[0].clientX;
      const y = e.clientY || e.touches[0].clientY;
      setDrawing({
        x: x - rect.left,
        y: y - rect.top,
      });
    }
  };

  const addText = () => {
    if (textInputRef.current) {
      const newText = {
        type: "text",
        color: currentColor,
        text: textValue,
        x: inputPosition.x,
        y: inputPosition.y,
        fontSize: textInputRef.current.style.fontSize || "16px",
      };
      setElements((prev) => [...prev, newText]);
      setInputPosition(null);
      setTextValue("");
    }
  };

  const handleSave = () => {
    const board = document.querySelector("#drawing-board");
    html2canvas(board).then((canvas) => {
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "drawing.png";
      link.click();
    });
  };

  const clearBoard = () => {
    setElements([]);
  };

  return (
    <Container>
      <Title>Tablero de Dibujo</Title>
      <BoardContainer>
        <Board
          id="drawing-board"
          ref={boardRef}
          onMouseDown={startDrawing}
          onMouseUp={stopDrawing}
          onMouseMove={handleDrawingMove}
          onTouchStart={startDrawing}
          onTouchEnd={stopDrawing}
          onTouchMove={handleDrawingMove}
        >
          {!drawing && <Instructions></Instructions>}
          {elements.map((el, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                top: el.y,
                left: el.x,
                backgroundColor: el.type === "draw" ? el.color : "transparent",
                width: el.type === "draw" ? el.thickness : "auto",
                height: el.type === "draw" ? el.thickness : "auto",
                borderRadius: el.type === "draw" ? "50%" : "0",
                fontSize: el.fontSize,
                fontFamily: "Poppins",
                color: el.color,
                whiteSpace: "nowrap",
              }}
            >
              {el.type === "text" ? el.text : ""}
            </div>
          ))}
          {inputPosition && currentTool === "text" && (
            <TextInput
              ref={textInputRef}
              style={{ top: inputPosition.y, left: inputPosition.x }}
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              onBlur={addText}
            />
          )}
        </Board>
      </BoardContainer>
      <ToolBar>
        <ToolButton onClick={() => setCurrentTool("draw")}>Dibujar</ToolButton>
        <ToolButton onClick={() => setCurrentTool("text")}>
          Agregar Texto
        </ToolButton>
        <ToolButton onClick={() => setCurrentTool("erase")}>Borrar</ToolButton>
        <ToolButton onClick={handleSave}>Guardar</ToolButton>
        <ToolButton onClick={clearBoard}>Limpiar</ToolButton>
      </ToolBar>
      <ColorPickerContainer>
        {colors.map((color) => (
          <ColorButton
            key={color}
            className="color-picker-button"
            style={{ backgroundColor: color }}
            selected={currentColor === color}
            onClick={() => setCurrentColor(color)}
          />
        ))}
      </ColorPickerContainer>
      <ThicknessPickerContainer>
        {thicknesses.map((thickness) => (
          <ThicknessButton
            key={thickness}
            className="thickness-picker-button"
            selected={currentThickness === thickness}
            onClick={() => setCurrentThickness(thickness)}
          >
            {thickness}
          </ThicknessButton>
        ))}
      </ThicknessPickerContainer>
      <Button onClick={() => navigate("/courses")}>Más Información</Button>
    </Container>
  );
};

export default DrawingBoard;
