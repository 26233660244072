import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from "react-router-dom";
import './Navbar.css';
import SideMenu from '../SideMenu/SideMenu.jsx';
import logo from '../../assets/logo.png';
import Home from '../Home/Home.jsx';
import Profesores from '../Profesores/Profesores.jsx';
import About from '../About/About.jsx';
import Courses from '../Courses/Courses.jsx';
import Sedes from '../Sedes/Sedes.jsx';
import Exint from '../Exint/Exint.jsx';
import Becas from '../Becas/Becas.jsx';
import Event from '../Event/Event.jsx';
import Works from '../Works/Works.jsx';
import Virtual from '../Virtual/Virtual.jsx';
import Contact from '../Contact/Contact.jsx';
import Draw from '../Draw/Draw.jsx';
import styled from "styled-components";
import { FaBars } from 'react-icons/fa';

const ListElements = styled.div`
  .navBar{
    width: 100%;
  }
`

const Navbar = () => {

  const [sticky, setSticky] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(()=>{
    window.addEventListener('scroll',()=>{
      window.scrollY > 50 ? setSticky(true) :setSticky(false);
    })
  },[])

  return (
    <ListElements>
    <nav className="navBar">
      <BrowserRouter>
          <header className={`container ${sticky? 'dark-nav' : ''}`}>
          <img src={ logo } alt="png" className='logo'/>
          <button onClick={toggleMenu} className="menu-button"><FaBars /></button>
          <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
          <div className="lista">
            <ul>
              <li>
                <Link to="/">
                  <h3>Inicio</h3>
                </Link>
              </li>
              <li>
                <Link to="/Courses">
                  <h3>Cursos</h3>
                </Link>
              </li>
              <li>
                <Link to="/Sedes">
                  <h3>Sedes</h3>
                </Link>
              </li>
              <li>
                <Link to="/Profesores">
                  <h3>Docentes</h3>
                </Link>
              </li>
              <li>
                <Link to="/About">
                  <h3>Sobre Nosotros</h3>
                </Link>
              </li>
              <li>
                <Link to="/Exint">
                  <h3>Ex. Internacionales</h3>
                </Link>
              </li>
              <li>
                <Link to="/Becas">
                  <h3>Becas</h3>
                </Link>
              </li>
              <li>
                <Link to="/Event">
                  <h3>Eventos</h3>
                </Link>
              </li>
              <li>
                <Link to="/Works">
                  <h3>Trabajo</h3>
                </Link>
              </li>
              <li>
                <Link to="/Virtual">
                  <h3>Virtual</h3>
                </Link>
              </li>
              <li>
                <Link to="/Draw">
                  <h3 className="Contact">Draw</h3>
                </Link>
              </li>
              <li>
                <Link to="/Contact">
                  <h3 className="Contact">Contacto</h3>
                </Link>
              </li>
              </ul>
          </div>
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Courses" element={<Courses />} />
            <Route path="/Sedes" element={<Sedes />} />
            <Route path="/Profesores" element={<Profesores />} />
            <Route path="/About" element={<About />} />
            <Route path="/Exint" element={<Exint />} />
            <Route path="/Becas" element={<Becas />} />
            <Route path="/Event" element={<Event />} />
            <Route path="/Works" element={<Works />} />
            <Route path="/Virtual" element={<Virtual />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Draw" element={<Draw />} />
          </Routes>
      </BrowserRouter>
    </nav>
  </ListElements>
  )
}

export default Navbar;