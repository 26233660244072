import React from "react";
import styled, { keyframes } from "styled-components";
import Footer from '../Footer/Footer';
import event from "./img/event.png";
import event36 from "./img/event36.jpg"
import event4 from "./img/event4.jpg"
import event1 from "./img/event1.jpg"
import event2 from "./img/event2.jpg"
import event3 from "./img/event3.jpg"
import event5 from "./img/event5.jpg"
import event6 from "./img/event6.jpg"
import event7 from "./img/event7.jpg"
import event8 from "./img/event8.jpg"

const FooterContainer = styled.div`
  @media screen and (max-width: 700px){
    font-size: 12px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Poppins', sans-serif;
`;

const EventsNav = styled.div`
  background-color: #007bff;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 50px;
`;

const Title = styled.h1`
  font-size: 2em;
  margin: 0;
`;

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ListItem = styled.li`
  margin: 10px;
  background-color: #2e2e4e;
  padding: 20px;
  border-radius: 20px;
  color: #ffffff;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #3e3e5e;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 15px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;

const Eventos = () => {
  const eventos = [
    {
      id: 1,
      titulo: "DIA DEL DOCENTE",
      fecha: "2023-05-15",
      hora: "17:01:29",
      organizador: "Andres Tutistar",
      cargo: "Administrativo",
      descripcion: "Cuando enseñar es un arte, aprender es un placer",
      imagen: event1,
    },
    {
      id: 2,
      titulo: "CONGRATULATIONS",
      fecha: "2023-03-31",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Grado 30 de marzo de 2023",
      imagen: event2,
    },
    {
      id: 3,
      titulo: "NOVENAS DE AGUINALDOS",
      fecha: "2022-12-02",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "FELIZ NAVIDAD",
      imagen: event3,
    },
    {
      id: 15,
      titulo: "CAMBRIDGE HALLOWEEN 2022",
      fecha: "2022-10-31",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "HALLOWEEN 2022",
      imagen: event4,
    },
    {
      id: 16,
      titulo: "DIA DE LA RAZA",
      fecha: "2022-10-17",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Vivir en cualquier parte del mundo hoy y estar contra la igualdad por motivo de raza o de color es como vivir en Alaska y estar contra la nieve.- Faulkner, William -",
      imagen: event5,
    },
    {
      id: 17,
      titulo: "PLAN RETOMA",
      fecha: "2022-08-22",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Retoma tus oportunidades de obtener mejores puestos laborales, retoma tus estudios en el idioma inglés, vuelve con nosotros:Al ser el inglés un idioma universal, te va a permitir interactuar con gente de todo el mundo, lo que se traduce en mejores oportunidades comerciales para las empresas. Es por eso que el mercado actual está solicitando personas capacitadas para comunicarse en inglés.",
      imagen: event,
    },
    {
      id: 18,
      titulo: "SALIDA CAMBRIDGE ACADEMY OF LANGUAGES",
      fecha: "2022-08-14",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Nuestros docentes son pieza fundamental en el aprendizaje de nuestros estudiantes, estimados docentes, gracias por formar parte de la familia Cambridge Academy Of Languages",
      imagen: event6,
    },
    {
      id: 19,
      titulo: "EXAMENES INTERNACIONALES",
      fecha: "2021-07-26",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 20,
      titulo: "ENCUENTRANOS EN REDES SOCIALES",
      fecha: "2021-01-25",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Ya puedes encontrarnos en Facebook y Twitter",
      imagen: event7,
    },
    {
      id: 21,
      titulo: "FORMULARIO TOTALMENTE GRATIS",
      fecha: "2020-12-09",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Hablar inglés para un profesional significa estar expuesto a muchas oportunidades profesionales, tanto laborales como en los negocios, pero aun sabiendo esto, muchos profesionales no hablan inglés, sin tener un gran diferencial al momento de buscar oportunidades laborales o de negocios.",
      imagen: event,
    },
    {
      id: 22,
      titulo: "APRENDE OTRO IDIOMA Y COMUNICATE CON EL MUNDO",
      fecha: "2020-11-10",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Actualmente, vivimos en un mundo totalmente globalizado y donde todos nos conectamos con todos. Es por esto que aprender idiomas constituye una gran herramienta a la hora de progresar como estudiante y profesional. Y por más que es recomendable aprender una segunda lengua desde la infancia, nunca es tarde para desarrollar esta habilidad.",
      imagen: event,
    },
    {
      id: 23,
      titulo: "CONVENIOS",
      fecha: "2020-10-28",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Si eres estudiante activo de alguna de estas instituciones educativas, obtén un super descuento sobre el valor de la fase a cursar.",
      imagen: event,
    },
    {
      id: 24,
      titulo: "APRENDE OTRO IDIOMA Y CONOCE DE NUEVAS CULTURAS",
      fecha: "2020-09-17",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 25,
      titulo: "AHORA TAMBIEN SOMOS CAMBRIDGE VIRTUAL",
      fecha: "2019-08-22",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Aprende/Disfruta/Crece",
      imagen: event,
    },
    {
      id: 26,
      titulo: "CAMBRIDGE IPIALES",
      fecha: "2020-02-05",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 27,
      titulo: "CAMBRIDGE KIDS, INGLES ESPECIALIZADO EN NIÑOS",
      fecha: "2019-10-07",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 28,
      titulo: "APRENDE PORTUGUES CON NOSOTROS",
      fecha: "2019-09-02",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 29,
      titulo: "APRENDE INGLES CON NOSOTROS",
      fecha: "2019-08-28",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Aprende/Disfruta/Crece",
      imagen: event,
    },
    {
      id: 30,
      titulo: "IPIALES,ESTACION DE EMPRESAS EXTRANJERAS.",
      fecha: "2019-08-26",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Si aprendes otro idioma, tendrás mas oportunidad de conseguir un empleo y con una mejor remuneración, también llevaras ventaja sobre los otros aspirantes al mismo cargo.",
      imagen: event,
    },
    {
      id: 31,
      titulo: "APRENDE FRANCES CON NOSOTROS",
      fecha: "2019-08-22",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 32,
      titulo: "LECTO-ESCRITURA,TECNICAS INTERNACIONALES DE LECTURA RAPIDA Y COMPRENSIVA",
      fecha: "2019-08-20",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 33,
      titulo: "APRENDE ALEMAN CON NOSOTROS",
      fecha: "2019-08-15",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "",
      imagen: event,
    },
    {
      id: 34,
      titulo: "ENCUENTRANOS AHORA EN WHATSAPP",
      fecha: "2019-08-02",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Ahora puedes encontrarnos en whatsapp con el numero 317 3169220",
      imagen: event8,
    },
    {
      id: 35,
      titulo: "DIA DE LA DIVERSIDAD CULTURAL",
      fecha: "2019-05-21",
      hora: "12:00:00",
      organizador: "Cambridge Academy",
      cargo: "Administrativo",
      descripcion: "Cuando los hombres buscan la diversidad viajan- Wenceslao Fernández Florez -",
      imagen: event,
    },
    {
      id: 36,
      titulo: "WELCOME TO CAMBRIDGE",
      fecha: "2015-08-12",
      hora: "14:42:03",
      organizador: "DCSM",
      cargo: "Administrativo",
      descripcion: "Cambridge Academy es una institución con más de 12 años de experiencia, dónde se fomenta el desarrollo humano con una visión universal a través de programas académicos bien estructurados que posibilitan obtener el certificado de calidad en los programas de idiomas a nivel de eficiencia comunicativa, ampliar el horizonte del conocimiento, la tecnología, adquisición de becas y exámenes internacionales e intercambios a otros países del mundo.",
      imagen: event36,
    },
  ];

  return (
    <div>
    <Container>
      <EventsNav>
        <Title>Eventos</Title>
      </EventsNav>
      <Content>
        <List>
          {eventos.map(evento => (
            <ListItem key={evento.id}>
              <ImageContainer>
                <Image src={evento.imagen} alt={`Evento ${evento.id}`} />
              </ImageContainer>
              <div>
                <h3>{evento.titulo}</h3>
                <p>{evento.fecha}</p>
                <p>{evento.hora}</p>
                <p>{evento.organizador} - {evento.cargo}</p>
                <p>{evento.descripcion}</p>
              </div>
            </ListItem>
          ))}
        </List>
      </Content>
    </Container>
    <div>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
    </div>
    
  );
};

export default Eventos;
