import React from "react";
import styled, { keyframes } from "styled-components";
import { FaChild, FaUsers, FaBook, FaFileAlt, FaUserCheck, FaClipboardList } from "react-icons/fa";
import franz from "./img/franzteresa.jpg";
import Footer from '../Footer/Footer';

const FooterContainer = styled.div`
  @media screen and (max-width: 700px){
    font-size: 12px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
`;

const CoursesNav = styled.div`
  background-color: #007bff;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 50px;

  @media (max-width: 700px) {
    padding: 15px;
    font-size: 1.2em;
  }
`;

const Title = styled.h1`
  font-size: 2em;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1.5em;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  animation: ${fadeIn} 1s ease-in-out;
  width: 100%;
  
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  max-width: 400px;
  height: auto;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 15px;
  display: flex; 
  justify-content: center; 
  align-items: center; 

  @media (max-width: 700px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; 
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;

const TextContainer = styled.div`
  background-color: #2e2e4e;
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
  line-height: 1.6;
  width: 50%;
  max-width: 400px;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  
  @media (max-width: 700px) {
    width: 100%;
    padding: 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-top: 20px;
  color: #00aaff;

  @media (max-width: 700px) {
    font-size: 1.2em;
    text-align: center;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%; 

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ListItem = styled.li`
  margin: 10px;
  background-color: #2e2e4e;
  padding: 20px;
  border-radius: 20px;
  color: #ffffff;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: #3e3e5e;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    padding: 15px;
    margin: 10px 0;
  }
`;

const Becas = () => {
  return (
    <div>
    <Container>
      <CoursesNav>
        <Title>Becas</Title>
      </CoursesNav>
      <Content>
        <SectionTitle>BECAS FRANZ Y TERESA GOOP</SectionTitle>
        <Section>
          <ImageContainer>
            <Image src={franz} alt="Becas Franz y Teresa Goop" />
          </ImageContainer>
          <TextContainer>
            “En nuestro componente de interacción y proyección social hemos querido otorgar 10 becas anuales a estudiantes de bajos recursos económicos que les permitirá ser competitivos en el estudio, en el trabajo y la convivencia. Además lograrán una visión pluralista del mundo y entrarán en contacto con otras maneras de pensar y de expresarse, a fin de que este reconocimiento de la diversidad lleve a un reconocimiento de la tolerancia y del respeto del otro/otra. El nombre de las becas corresponde a dos personas que entregaron gran parte de su vida, de su experiencia pedagógica, su calidad humana, su calidad intelectual a transformar vidas en nuestra región. Las personas que nos vimos beneficiadas por su aporte queremos hacer un reconocimiento a través de estas becas; porque su objetivo fue siempre apoyar al más necesitado, al desprotegido, al olvidado; no solo con algo material sino con algo que está por encima del dinero, el sentido humano, el conocimiento, la cultura, el compartir, el reconocer los valores en los demás y la apreciación de un mundo más equitativo a través de una justicia social que cada uno de nosotros podamos construir a partir de nuestra propia realidad. Y es lo que intentamos a partir de estas becas. Gracias a Franz Xaver Goop y Teresa Goop por enseñarnos a ver el mundo de una manera diferente que nos ha permitido ser felices.”
          </TextContainer>
        </Section>
        <SectionTitle>REQUISITOS PARA APLICAR A LAS BECAS</SectionTitle>
        <List>
          <ListItem><FaChild size={32} /> Tener entre 6 a 17 años.</ListItem>
          <ListItem><FaUsers size={32} /> Pertenecer a un estrato social de alta vulnerabilidad y presentar soportes.</ListItem>
          <ListItem><FaBook size={32} /> Tener el compromiso, la motivación y el interés de asumir un proceso de formación académica en el idioma Inglés.</ListItem>
          <ListItem><FaFileAlt size={32} /> Realizar la solicitud formal por escrito, anexar fotocopia del documento de identidad.</ListItem>
          <ListItem><FaUserCheck size={32} /> Entrevista personal.</ListItem>
          <ListItem><FaClipboardList size={32} /> Una vez seleccionado(a) realizar el proceso de matrícula académica (formulario de inscripción, carnetización).</ListItem>
        </List>
      </Content>
    </Container>
    
  <div>
    <FooterContainer>
      <Footer />
  </FooterContainer>
  </div>
  </div>
  );
};

export default Becas;