import React, { useEffect, useState } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Popup from "./Components/Popup/Popup";
import "./App.css";

const App = () => {
  const [isPopupOpen, setPopupOpen] = useState(true);

  return (
    <div>
      <Navbar />
      <Popup isOpen={isPopupOpen} onClose={() => setPopupOpen(false)}>
        <div className="popup-text">
          <h2>Cambridge Academy Of Languages te informa que:</h2>
          <ul>
            <li><strong>🤵</strong> Para ingresar a la plataforma de notas</li>
            <li><strong>🗒️</strong> Para inscribirse de manera virtual</li>
            <li><strong>💵</strong> Para pagos en línea</li>
            <li><strong>❓</strong> Para los instructivos de cómo pagar</li>
          </ul>
          <p className="important-note">
            <strong>A TOMAR EN CUENTA:</strong> Todos estos símbolos están en la parte izquierda de la página o en caso del celular, por favor desplegar las tres barritas de la parte izquierda.
          </p>
        </div>
      </Popup>
    </div>
  );
};

export default App;
