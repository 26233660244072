// Footer.jsx
import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background-color: #353451;
  color: #ffffff;
  padding: 40px 0 0 0;
  width: 101%;
  font-size: 10px;
`;

const FooterDevTeam = styled.div`
  bottom: 0;
  background-color: #353451;
`

const H1 = styled.h1`
  color:rgba(172, 172, 172, 0.12);
  text-align: center;
  font-size: 12px;
`

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  font-family: Poppins;
`;

const Section = styled.div`
  flex: 1;
  min-width: 250px;
  margin: 10px 0;
`;

const SectionTitle = styled.h3`
  margin-bottom: 15px;
`;

const Link = styled.a`
  display: block;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const ContactInfo = styled.p`
  margin: 5px 0;
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 20px;
  &:hover {
    color: #dddddd;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #444;
  padding-top: 10px;
  font-size: 14px;
  font-family: Poppins;
`;

const Footer = () => {
  return (
    <div>
    <FooterContainer>
      <FooterContent>
        <Section>
          <SectionTitle>Contacto Pasto</SectionTitle>
          <ContactInfo>Cra. 35 No. 19-102 Br. Versalles</ContactInfo>
          <ContactInfo>Cra. 34A No. 20-68 Br. La Riviera</ContactInfo>
          <ContactInfo>Tel: 7313015 - 7316922 - 7230894</ContactInfo>
          <ContactInfo>Cel: 3173787109</ContactInfo>
          <ContactInfo>Email: informacionpasto@cambridgeacademy.edu.co</ContactInfo>
        </Section>
        <Section>
          <SectionTitle>Contacto Ipiales</SectionTitle>
          <ContactInfo>Cra. 8va No. 24A-64 Br. San Fernando</ContactInfo>
          <ContactInfo>Tel: 7736535 - 7758068</ContactInfo>
          <ContactInfo>Email: informacionipiales@cambridgeacademy.edu.co</ContactInfo>
          <SocialMedia>
            <SocialIcon href="https://www.facebook.com/cambridgeacademyoflanguages">F</SocialIcon>
            <SocialIcon href="https://x.com/Cambridge_Acad">T</SocialIcon>
          </SocialMedia>
        </Section>
      </FooterContent>
      <FooterBottom>
        &copy; 2012 - 2024 Cambridge
      </FooterBottom>
    </FooterContainer>
      <FooterDevTeam>
        <H1>Desarrollado por: Tomás Benavides, Fernando Rosero y Danilo Montezuma</H1>
      </FooterDevTeam>
    </div>
  );
};

export default Footer;