import React from "react";
import styled, { keyframes } from "styled-components";
import mundi from "./img/mundi.png";
import Footer from "../Footer/Footer";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  margin-top: 5%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${mundi});
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 700px) {
  font-size: 25px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Card = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  animation: ${fadeIn} 1s ease-in-out;
  transition: transform 0.3s;
  width: 90%;

  &:hover {
    transform: scale(1.05);
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
    color: #ffffff;

    strong {
      color: #60b5ff;
    }
  }

  &.full-width {
    grid-column: span 2;
    width: 95%;

    @media (max-width: 767px) {
      grid-column: span 1;
    }
  }
`;

const FooterContainer = styled.footer`
  width: 100%;
  font-size: 12px;
`;

const Exint = () => {
  return (
    <PageContainer>
      <ContentContainer>
        <div className="coursesnav">
          <Title>Exámenes Internacionales</Title>
        </div>
        <Grid>
          <Card className="full-width">
            <p>
              <strong>EXÁMENES INTERNACIONALES</strong><br />
              Cambridge Academy es la única institución en la ciudad que cuenta con convenios con ETS E.E.U.U para aplicación de exámenes TOEFL iBT y GRE, con el British Council para aplicación de exámenes como F.C.E., IELTS, APTIS, PET, KET, FLYERS y Oxford Quality de la Universidad de Oxford. Todos aplicados en nuestra institución.
            </p>
          </Card>
          <Card>
            <p>
              <strong>Objetivo</strong><br />
              Certificar a nivel internacional el grado de dominio de una segunda lengua en las cuatro habilidades y según el Marco Común Europeo.
            </p>
          </Card>
          <Card>
            <p>
              <strong>Fases</strong><br />
              Training / Presentación de examen.
            </p>
          </Card>
          <Card>
            <p>
              <strong>Duración</strong><br />
              En horarios de lunes a viernes: un mes.<br />
              Fines de semana: dos meses.<br />
              Personalizado: de acuerdo a la disposición del estudiante.
            </p>
          </Card>
          <Card>
            <p>
              <strong>Horarios</strong><br />
              Lunes a viernes: 8:00 A.M., 10:00 A.M., 2:00 P.M., 4:00 P.M., 6:30 P.M.<br />
              Martes y jueves: 5:00 P.M. a 6:30 P.M.<br />
              Fines de semana: 8:30 A.M. a 12:00 M. ó 2:00 P.M. a 5:30 P.M.
            </p>
          </Card>
          <Card>
            <p>
              <strong>Edades</strong><br />
              De 11 años en adelante.
            </p>
          </Card>
        </Grid>
      </ContentContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </PageContainer>
  );
};

export default Exint;