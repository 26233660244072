import React from "react";
import { FaUser, FaPen, FaSearch, FaChalkboardTeacher } from "react-icons/fa";
import "./Topbar.css";
import logoPSE from "./icon/PSE.png";

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="topbar-icon">
        <FaUser />
      </div>
      <div className="topbar-icon">
        <a href="https://www.cambridgeacademy.edu.co/SGAA/" target="_blank" rel="noopener noreferrer">
          <h3 className="topbartext">Plataforma virtual notas</h3>
        </a>
      </div>
      <div className="topbar-icon">
        <FaChalkboardTeacher />
        <a href="https://site2.q10.com/login?ReturnUrl=%2F" target="_blank" rel="noopener noreferrer">
          <h3 className="topbartext">Clases virtuales</h3>
        </a>
      </div>
      <div className="topbar-icon">
        <a href="https://site3.q10.com/preinscripcion?aplentId=ca7edead-fa25-4d9d-8a1a-9f0ea97f1cde" target="_blank" rel="noopener noreferrer">
          <h3 className="topbartext">Inscripciones</h3>
        </a>
      </div>
      <div className="topbar-4icon">
        <img className="pseIcon" src={logoPSE} alt="Custom Icon" />
      </div>
      <div className="topbar-icon">
        <a href="https://www.zonapagos.com/t_Cambridgeacademy/" target="_blank" rel="noopener noreferrer">
          <h3 className="topbartext">Pagos en línea</h3>
        </a>
      </div>
      <div className="topbar-icon">
        <FaSearch />
      </div>
      <div className="topbar-icon">
        <a href="https://www.cambridgeacademy.edu.co/Docs/pagos_virtuales.pdf" target="_blank" rel="noopener noreferrer">
          <h3 className="topbartext">Cómo pagar</h3>
        </a>
      </div>
    </div>
  );
};

export default Topbar;
