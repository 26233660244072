import React from "react";
import styled, { keyframes } from "styled-components";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background: #1e1d3d;
  color: #fff;
  animation: ${fadeIn} 2s ease-in-out;
  align-items: center;
  text-align: center;

  @media screen and (700px) {
    max-width: 120%;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
`;

const P = styled.h1`
  font-size: 15px;
  margin-bottom: 20px;
  max-width: 90%;
  text-align: justify;
  text-align: center;
  margin-left: 80px;

  @media (max-width: 700px) {
  margin-left: 0;
  max-width: 100%;
  }
}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const AnimatedButton = styled(animated.button)`
  padding: 15px 30px;
  font-size: 1.2em;
  color: #fff;
  background-color: #2980b9;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    background-color: #3498db;
  }
`;

const Card = styled(animated.div)`
  background: #353451;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 300px;
  text-align: center;
`;

const cards = [
  {
    title: "Excelencia Académica",
    description: "Más de 15 años formando a los mejores profesionales.",
  },
  {
    title: "Certificaciones Internacionales",
    description: "Reconocidos por entidades internacionales de prestigio.",
  },
  {
    title: "Ambiente Multicultural",
    description: "Diversidad y enriquecimiento cultural en cada clase.",
  },
];

const Aditional = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const springProps = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(50px)",
    },
    from: { opacity: 0, transform: "translateY(50px)" },
    config: { tension: 200, friction: 20 },
  });

  return (
    <Section ref={ref}>
      <animated.div style={springProps}>
        <Title>Bienvenido a Cambridge Academy</Title>
        <br />
        <p>
          Cambridge Academy of Languages es una institución certificada por la
          universidad de Oxford e International Certification & Training (IC&T),
          con más de 20 años de experiencia en la enseñanza de idiomas. Nos
          dedicamos a proporcionar una educación de alta calidad que permita a
          nuestros estudiantes alcanzar la competencia lingüística deseada.
          Desde nuestros inicios, hemos trabajado incansablemente para ofrecer
          programas educativos que no solo se centren en el aprendizaje teórico
          del idioma, sino también en su aplicación práctica en situaciones
          cotidianas y profesionales.
        </p>
        <p>
          Además, en Cambridge Academy of Languages valoramos la diversidad
          cultural y creemos que el aprendizaje de un idioma es una puerta hacia
          la comprensión y el respeto entre diferentes culturas. Por ello,
          fomentamos un ambiente inclusivo y multicultural en todas nuestras
          sedes. Nos enorgullece ver cómo nuestros estudiantes no solo mejoran
          sus competencias lingüísticas, sino que también desarrollan una mayor
          empatía y comprensión global.
        </p>
        <p>
          A lo largo de los años, hemos ayudado a miles de estudiantes a
          alcanzar sus objetivos académicos y profesionales, y seguimos
          comprometidos con nuestra misión de brindar una educación lingüística
          de excelencia. Únete a nosotros y descubre cómo el aprendizaje de un
          nuevo idioma puede abrirte un mundo de oportunidades.
        </p>
        <ButtonWrapper>
          <AnimatedButton style={springProps}>Más Información</AnimatedButton>
          <AnimatedButton style={springProps}>
            <a href="https://site3.q10.com/preinscripcion?aplentId=ca7edead-fa25-4d9d-8a1a-9f0ea97f1cde">
              Inscríbete Ahora
            </a>
          </AnimatedButton>
        </ButtonWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {cards.map((card, index) => (
            <Card key={index} style={springProps}>
              <h2>{card.title}</h2>
              <p>{card.description}</p>
            </Card>
          ))}
        </div>
      </animated.div>
    </Section>
  );
};

export default Aditional;
