import React from "react";
import styled, { keyframes } from "styled-components";
import HeaderImage from "./img/header.jpg";
import { FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import Footer from "../Footer/Footer";

const FooterContainer = styled.div`
  font-size: 12px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1d3d;
  padding: 80px;
  color: white;
  font-family: "Poppins", sans-serif;
  animation: ${fadeIn} 1s ease-in;
  min-height: 100vh;

  @media (max-width: 700px) {
    padding: 20px;
    margin-top: 90px;
  }
`;

const Header = styled.div`
  width: 80%;
  height: 150px;
  background-image: url(${HeaderImage});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  padding: 40px 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;

  @media (max-width: 700px) {
    height: 100px;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  position: relative;
  z-index: 1;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1.5rem;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  z-index: 0;
`;

const LocationsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 30px;
  padding: 20px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const LocationBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 1s ease-in;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 700px) {
    font-size: 1rem;
    padding: 15px;
  }
`;

const LocationTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    font-size: 1.2rem;
  }
`;

const LocationAddress = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;

  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;

const LocationPhone = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;

  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 700px) {
    padding: 0 10px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 700px) {
    padding: 8px;
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  height: 150px;
  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 700px) {
    padding: 8px;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 700px) {
    padding: 8px;
  }
`;

const Contact = () => {
  const locations = [
    {
      title: "Sede principal",
      address: "Carrera 35 N° 19 - 102 Versalles",
      phone: "317 3169220",
    },
    {
      title: "Sede parque Bolívar",
      address: "Calle 21 # 12 - 45",
      phone: "3170495555",
    },
    {
      title: "Cambridge Kids",
      address: "Carrera 34A N° 20 - 88",
      phone: "6027230894 - 3185774865",
    },
    {
      title: "Ipiales",
      address: "Carrera 8 # 24A - 64 Barrio San Fernando",
      phone: "3175806885",
    },
  ];

  const googleMapSrc =
    "https://www.google.com/maps/d/embed?mid=1_rF9akcbps0GV8X0tAdbssMDew4l7IE&ehbc=2E312F";

  return (
    <div>
      <Container>
        <Header>
          <Overlay />
          <Title>Contacto</Title>
        </Header>
        <div
          style={{
            width: "100%",
            height: "400px",
            marginTop: "20px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <iframe
            src={googleMapSrc}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="iframe-1"
          ></iframe>
        </div>
        <LocationsContainer>
          {locations.map((location, index) => (
            <LocationBox key={index}>
              <LocationTitle>
                <FaMapMarkerAlt /> {location.title}
              </LocationTitle>
              <LocationAddress>
                <FaMapMarkerAlt /> {location.address}
              </LocationAddress>
              <LocationPhone>
                <FaPhone /> {location.phone}
              </LocationPhone>
            </LocationBox>
          ))}
        </LocationsContainer>
        <FormContainer>
          <Form>
            <Label htmlFor="name">Nombre</Label>
            <Input type="text" id="name" placeholder="Nombre" required />
            <Label htmlFor="email">Correo</Label>
            <Input type="email" id="email" placeholder="Correo" required />
            <Label htmlFor="message">Mensaje</Label>
            <TextArea id="message" placeholder="Mensaje" required></TextArea>
            <Button type="submit">Enviar</Button>
          </Form>
        </FormContainer>
      </Container>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  );
};

export default Contact;