import React from "react";
import "./Popup.css";
import crossImage from "../../assets/cross.png"; 

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {children}
        <button className="close-btn" onClick={onClose}>
          <img src={crossImage} alt="Cerrar" className="close-image" />
        </button>
      </div>
    </div>
  );
};

export default Popup;
