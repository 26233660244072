import styled from "styled-components";
import { Profesores } from "./Profesores.1";

export const Container = styled.div`

  * {
    color: #fff;
  }

  .reviewsSection {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
  }

  @media (max-width: 700px) {
    .reviewsSection {
      width: 100%;
      padding: 30px 10px;
      margin-top: 10%;
    }
  }

  .reviewTextSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .reviewSectionTitle {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    font-size: 60px;
    color: #fff;
  }

  .reviewSectionSubtitle {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: rgb(127, 127, 127);
  }

  .allReviews {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }

  .review {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 4px rgba(127, 127, 127, 0.5);
    background-color: #353451;
    min-width: 460px;
    height: 150px;
    font-family: 'Poppins', sans-serif;
    transition: all ease-in-out 0.3s;
  }

  .review:hover {
    transform: scale(1.075);
    cursor: pointer;
    transition: all ease-in-out 0.3s;
  }

  .profilePic {
    display: flex;
    align-items: flex-start;
  }

  .profilePic img {
    border-radius: 100px;
    width: 100px;
  }

  .reviewText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .reviewerName {
    font-weight: bold;
    font-size: x-large;
  }

  @media screen and (max-width: 700px) {
    .review {
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      margin-left: 40px;
      height: auto;
      align-items: center;
    }

    .profilePic {
      justify-content: center;
    }

    .reviewText {
      text-align: center;
      align-items: center;
    }
  }
`;

export default Profesores;
